import { useIdentity } from "@verified/sspa-core-lib";

export default function Root(props) {
  const [session] = useIdentity(false);

  if (session === false) return <div>loading...</div>;
  if (!session?.user) return null;
  return (
    <>
      <div>
        Here be Dashboard dragons
      </div>
    </>
  );
}
